@font-face {
  font-family: 'Roboto';
  src: url("../public/assets/fonts/Roboto-Light.ttf") format('truetype');
  font-weight: 300;
}

@font-face {
  font-family: 'Roboto';
  src: url("../public/assets/fonts/Roboto-Regular.ttf") format('truetype');
  font-weight: 400;
}

body {
  height: 100vh;
  overflow-x: hidden;
}

@font-face {
  font-family: 'Roboto';
  src: url("../public/assets/fonts/Roboto-Bold.ttf") format('truetype');
  font-weight: 700;
}

#root {
  height: 100%;
  display: flex;
  flex-direction: column;
}

#builder-main-layout {
  display: grid;
  grid-template-columns: 344px 1fr auto;
  flex-grow: 1;
}

#builder-workbench {
  display: grid;
}

#builder-canvas {
  background-color: #f5f5f5;
  padding: 32px 24px;
  height: calc(100vh - 242px);
  overflow-y: auto
}

#builder-wrapper-view {
  display: grid;
  grid-template-rows: 1fr 132px;
}
