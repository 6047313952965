.page,
.page:hover,
.page.Mui-selected,
.page.Mui-focused {
    background: #88C559 !important;
    color: #ffffff;
    padding-right: 90px !important;
}

.root,
.root:hover,
.root.Mui-selected,
.root.Mui-focused,
.subcategory {
    background: #abd68b !important;
    color: #445638;
    padding-right: 90px !important;
}

.child {
    border-bottom: 2px solid #E4EBDF !important;
    color: #445638;
    padding-right: 90px !important;
}

.child:hover, .child.Mui-focused, .child.Mui-selected {
    background: transparent !important;
}



